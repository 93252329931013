import EmailSignIn from "./Pages/EmailSignIn.js";
import OtpSignIn from "./Pages/OtpSignIn.js";
import Nav from "./Pages/Nav/Nav";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

function App() {
  return (
    <div className=" h-screen">
      <Nav />
        <Routes>
          <Route path="/" exact element={<EmailSignIn />} />
          <Route path="OtpSignIn" exact element={<OtpSignIn />} />
        </Routes>
    </div>
  );
}

export default App;
