import { useLocation } from "react-router-dom";
import logo from "../../probietech logo.png";

const Nav = () => {
  const location = useLocation(); // Get the current location

  // Determine if the current path is active
  const isActive = (path) => location.pathname === path;

  return (
    <div className="flex shadow-sm h-24 w-full rounded-sm text-xl text-gray-400">
      <ul className="flex items-center flex-row w-full justify-between mx-4">
        <li className="font-semibold flex flex-row justify-center items-center">
          <img src={logo} className="h-20 " alt="logo" /> Probietech - Firebase{" "}
          <span className="text-[#d4843C] ml-2 font-semibold">Login</span>
        </li>
        <li className="mr-10 flex-row flex py-6">
          <div
            className={`font-semibold cursor-pointer hover:text-[#d4843C] ${
              isActive("/") ? "text-[#d4843C]" : ""
            }`}
            onClick={() => (window.location.href = "/")}
          >
            SignIn With Email
          </div>
          <div
            className={`ml-8 font-semibold cursor-pointer hover:text-[#d4843C] ${
              isActive("/OtpSignIn") ? "text-[#d4843C]" : ""
            }`}
            onClick={() => (window.location.href = "/OtpSignIn")}
          >
            SignIn With OTP
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Nav;
