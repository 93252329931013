import React, { useState, useEffect } from "react";
import { getApps, initializeApp, deleteApp } from "firebase/app";
import {
  AtSymbolIcon,
  EyeIcon,
  EyeOffIcon,
  IdentificationIcon,
  KeyIcon,
  LockClosedIcon,
} from "@heroicons/react/solid"; // Import eye icons (you can use any icon library)
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";

const EmailSignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [projectId, setProjectId] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [savedProjects, setSavedProjects] = useState([]);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const firebaseConfig = {
      apiKey: apiKey,
      authDomain: `${projectId}.firebaseapp.com`,
      projectId: projectId,
    };

    console.log("Email:", email);
    console.log("Password:", password);
    console.log("Firebase API Key:", apiKey);

    try {
      const apps = getApps();
      if (apps.length > 0) {
        await deleteApp(apps[0]);
      }

      const app = initializeApp(firebaseConfig);
      const auth = getAuth(app);

      let userCredential;
      try {
        userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
      } catch (signInError) {
        console.error("Sign-in error:", signInError);

        // Handle wrong password separately
        if (signInError.code === "auth/wrong-password") {
          setError(
            "Invalid credentials. Please check your email and password."
          );
          setIsLoading(false);
          return;
        }

        // Handle user-not-found specifically for new user creation
        if (signInError.code) {
          try {
            userCredential = await createUserWithEmailAndPassword(
              auth,
              email,
              password
            );
          } catch (createUserError) {
            console.error("Error creating user:", createUserError);
            setError(createUserError.message);
            setIsLoading(false);
            return;
          }
        } else {
          setError("An unexpected error occurred. Please try again.");
          setIsLoading(false);
          return;
        }
      }
      const userToken = await userCredential.user.getIdToken();
      setToken(userToken);
      setError("");
      setCopySuccess("");

      // Handle `rememberMe` logic
      if (rememberMe) {
        const storedProjects =
          JSON.parse(localStorage.getItem("savedProjects")) || [];
        const existingProjectIndex = storedProjects.findIndex(
          (project) =>
            project.projectId === projectId && project.email === email
        );

        if (existingProjectIndex >= 0) {
          storedProjects[existingProjectIndex].token = userToken;
        } else {
          const newProject = {
            apiKey,
            projectId,
            email,
            password,
            token: userToken,
          };
          storedProjects.push(newProject);
        }

        setSavedProjects(storedProjects);
        localStorage.setItem("savedProjects", JSON.stringify(storedProjects));
      }
    } catch (error) {
      console.error("General Firebase initialization error:", error);
      setError("Invalid Firebase Project ID, API Key, or Configuration.");
      setToken("");
    } finally {
      setIsLoading(false);
    }
  };
  const handleCopy = () => {
    if (token) {
      navigator.clipboard
        .writeText(token)
        .then(() => setCopySuccess("Token copied to clipboard!"))
        .catch(() => setCopySuccess("Failed to copy token."));
    }
  };
  useEffect(() => {
    try {
      const savedProjectsData = localStorage.getItem("savedProjects");
      if (savedProjectsData) {
        setSavedProjects(JSON.parse(savedProjectsData));
      } else {
        setSavedProjects([]);
      }
    } catch (error) {
      console.error("Error parsing saved projects:", error);
      setSavedProjects([]);
    }
  }, []);

  const removeProject = (projectIdToRemove) => {
    const updatedProjects = savedProjects.filter(
      (project) => project.projectId !== projectIdToRemove
    );
    setSavedProjects(updatedProjects);
    localStorage.setItem("savedProjects", JSON.stringify(updatedProjects));
  };

  const autofillProject = (project) => {
    setProjectId(project.projectId);
    setApiKey(project.apiKey);
    setEmail(project.email);
    setPassword(project.password);
    setToken(""); // Clear token when autofilling
  };

  return (
    <div>
      <div className="flex mt-10 text-xl">
        <div className="flex items-center justify-center w-1/2 bg-white p-6">
          <form onSubmit={handleSubmit} className="w-full max-w-md">
            <h2 className="text-2xl font-bold text-center mb-12 text-gray-500">
              SignIn With Email
            </h2>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="apiKey"
              >
                Firebase API Key
              </label>
              <div className="flex items-center border border-gray-300 rounded-lg">
                <KeyIcon className="h-5 w-5 text-gray-500 ml-2" />
                <input
                  type="text"
                  id="apiKey"
                  className="w-full px-4 py-2 rounded-lg focus:outline-none"
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="projectId"
              >
                Firebase Project ID
              </label>
              <div className="flex items-center border border-gray-300 rounded-lg">
                <IdentificationIcon className="h-5 w-5 text-gray-500 ml-2" />
                <input
                  type="text"
                  id="projectId"
                  className="w-full px-4 py-2 rounded-lg focus:outline-none"
                  value={projectId}
                  onChange={(e) => setProjectId(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <div className="flex items-center border border-gray-300 rounded-lg">
                <AtSymbolIcon className="h-5 w-5 text-gray-500 ml-2" />
                <input
                  type="email"
                  id="email"
                  className="w-full px-4 py-2 rounded-lg focus:outline-none"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="mb-10">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <div className="flex items-center border border-gray-300 rounded-lg relative">
                <LockClosedIcon className="h-5 w-5 text-gray-500 ml-2" />
                <input
                  type={showPassword ? "text" : "password"} // Toggle between text and password
                  id="password"
                  className="w-full px-4 py-2 border-0 focus:outline-none"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  {showPassword ? (
                    <EyeOffIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <EyeIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </div>
            <div className="mb-4">
              <input
                type="checkbox"
                id="rememberMe"
                className="mr-2"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <label htmlFor="rememberMe" className="text-gray-700">
                Remember Me
              </label>
            </div>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <button
              type="submit"
              className={`bg-indigo-500 text-white py-2 px-4 rounded focus:outline-none flex items-center ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isLoading}
            >
              {isLoading ? (
                <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  />
                </svg>
              ) : (
                "Submit"
              )}
              {isLoading ? "Processing..." : ""}
            </button>
          </form>
        </div>
        <div className="flex items-center justify-center w-1/2 p-16">
          {token ? (
            <div className="bg-green-100 p-10 rounded-lg shadow-lg w-full">
              <h3 className="text-lg font-bold mb-4">Token:</h3>
              <p className="text-green-600 break-words mb-4">{token}</p>
              <button
                onClick={handleCopy}
                className="bg-gray-500 text-white py-2 px-4 focus:outline-none"
              >
                Copy
              </button>
              {copySuccess && <p className="font-bold mt-4">{copySuccess}</p>}
            </div>
          ) : (
            <p className="text-gray-500">
              Submit the form to display the token here.
            </p>
          )}
        </div>
      </div>{" "}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 justify-items-center">
        {savedProjects.map((project, index) => (
          <div
            key={index}
            className="bg-gray-200 p-5 rounded-lg shadow-lg h-72 w-full flex flex-col justify-center items-center"
          >
            <h3 className="text-lg font-bold mb-4 text-center">
              Saved - {project.projectId}
            </h3>
            <p className="text-gray-600 mb-4 font-bold text-center">
              Project ID: {project.projectId}
            </p>
            <p className="text-gray-600 mb-4 font-bold text-center">
              Email: {project.email}
            </p>
            <div className="flex justify-center mt-4">
              <button
                onClick={() => autofillProject(project)}
                className="bg-blue-500 text-white py-2 px-4 mr-2 focus:outline-none rounded-lg"
              >
                Autofill
              </button>
              <button
                onClick={() => removeProject(project.projectId)}
                className="bg-red-500 text-white py-2 px-4 focus:outline-none rounded-lg"
              >
                Remove
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmailSignIn;

// identification;
// at - symbol;
