import React, { useState, useEffect } from "react";
import { initializeApp, getApps, getApp } from "firebase/app";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const OtpSignIn = () => {
  const [apiKey, setApiKey] = useState("");
  const [projectId, setProjectId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [appId, setAppId] = useState("");

  useEffect(() => {
    if (apiKey && projectId && appId) {
      initializeFirebase();
    }

    if (!window.recaptchaVerifier && getApps().length > 0) {
      const auth = getAuth();
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: () => {
          },
          "expired-callback": () => {
            setError("reCAPTCHA expired, please try again.");
          },
        },
        auth
      );
    }
  }, [apiKey, projectId, appId]);

  const initializeFirebase = () => {
    if (!getApps().length) {
      const firebaseConfig = {
        apiKey: apiKey,
        authDomain: `${projectId}.firebaseapp.com`,
        projectId: projectId,
        appId: appId,
      };
      initializeApp(firebaseConfig);
      getAnalytics(getApp());
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!apiKey || !projectId || !appId || !phoneNumber) {
      setError("All fields are required!");
      return;
    }

    initializeFirebase();

    const auth = getAuth();
    auth.useDeviceLanguage();

    try {
      const appVerifier = window.recaptchaVerifier;
      const result = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      setConfirmationResult(result);
      setIsOtpSent(true);
      setError("");
    } catch (error) {
      setError("Failed to send OTP: " + error.message);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    try {
      const result = await confirmationResult.confirm(otp);
      const userToken = await result.user.getIdToken();
      setToken(userToken);
      setError("");
      setIsOtpSent(false);
    } catch (error) {
      setError("Failed to verify OTP: " + error.message);
    }
  };

  const handleCopy = () => {
    if (token) {
      navigator.clipboard.writeText(token);
      setCopySuccess("Token copied to clipboard!");
    }
  };

  return (
    <div className="flex mt-10 text-xl">
      <div className="flex items-center justify-center w-1/2 bg-white p-6">
        <form
          onSubmit={isOtpSent ? handleVerifyOtp : handleSubmit}
          className="w-full max-w-md"
        >
           <h2 className="text-2xl font-bold text-center mb-12 text-gray-500">
            SignIn With PhoneNumber
          </h2>
          <div className="mb-4">
            <label
              className="block text-gray-700  font-bold mb-2"
              htmlFor="apiKey"
            >
              Firebase API Key
            </label>
            <input
              type="text"
              id="apiKey"
              className="w-full px-4 py-2 border rounded-lg  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="projectId"
            >
              Firebase Project ID
            </label>
            <input
              type="text"
              id="projectId"
              className="w-full px-4 py-2 border rounded-lg  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={projectId}
              onChange={(e) => setProjectId(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="appId"
            >
              Firebase App ID
            </label>
            <input
              type="text"
              id="appId"
              className="w-full px-4 py-2 border rounded-lg  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={appId}
              onChange={(e) => setAppId(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="phoneNumber"
            >
              Phone Number
            </label>
            <input
              type="tel"
              id="phoneNumber"
              className="w-full px-4 py-2 border rounded-lg  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </div>

          {isOtpSent && (
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="otp"
              >
                OTP
              </label>
              <input
                type="text"
                id="otp"
                className="w-full px-4 py-2 border rounded-lg  border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </div>
          )}

          {error && <p className="text-red-500 mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white rounded-lg  py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {isOtpSent ? "Verify OTP" : "Send OTP"}
          </button>
        </form>
      </div>

      <div className="flex flex-col items-center justify-center w-1/2 p-16">
        {token ? (
          <div className="bg-green-100 p-10 shadow-lg w-full ">
            <h3 className="text-lg font-bold mb-4">Token:</h3>
            <p className="text-green-600 break-words mb-4">{token}</p>
            <button
              onClick={handleCopy}
              className="bg-gray-500 text-white py-2 px-4 rounded-lg  hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Copy
            </button>
            {copySuccess && <p className="font-bold mt-4">{copySuccess}</p>}
          </div>
        ) : (
          <p className="text-gray-500">Submit the form to display the token here.</p>
        )}
      </div>

      <div id="recaptcha-container"></div>
    </div>
  );
};

export default OtpSignIn;
